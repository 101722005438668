import styled from "styled-components";

import { BACKGROUND_COLOR, BORDER_RADIUS, SPACING } from "../constants";

export default function Home() {
  return (
    <Component>
      <Header>Who are we?</Header>
      <Paragraph>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged. It was popularised in the 1960s with the release
        of Letraset sheets containing Lorem Ipsum passages, and more recently
        with desktop publishing software like Aldus PageMaker including versions
        of Lorem Ipsum.
      </Paragraph>
      <Paragraph>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged. It was popularised in the 1960s with the release
        of Letraset sheets containing Lorem Ipsum passages, and more recently
        with desktop publishing software like Aldus PageMaker including versions
        of Lorem Ipsum.
      </Paragraph>
      <Header>What do we do?</Header>
      <Paragraph>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged. It was popularised in the 1960s with the release
        of Letraset sheets containing Lorem Ipsum passages, and more recently
        with desktop publishing software like Aldus PageMaker including versions
        of Lorem Ipsum.
      </Paragraph>
      <Paragraph>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged. It was popularised in the 1960s with the release
        of Letraset sheets containing Lorem Ipsum passages, and more recently
        with desktop publishing software like Aldus PageMaker including versions
        of Lorem Ipsum.
      </Paragraph>
    </Component>
  );
}

const Component = styled.div`
  background-color: ${BACKGROUND_COLOR};
  border-top-left-radius: ${BORDER_RADIUS};
  border-top-right-radius: ${BORDER_RADIUS};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-top: ${SPACING};
  padding-left: 120px;
  padding-right: 120px;
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;

  @media only screen and (max-width: 1100px) {
    padding-left: 70px;
    padding-right: 70px;
  }

  @media only screen and (max-width: 700px) {
    padding-left: 40px;
    padding-right: 40px;
  }
`;

const Header = styled.h1`
  font-family: "Fraunces", serif;
  font-optical-sizing: auto;
  font-size: 25px;
  font-style: normal;
  font-variation-settings: "SOFT" 0, "WONK" 0;
  letter-spacing: 0.5px;
  text-transform: lowercase;
`;

const Paragraph = styled.p`
  font-family: "Fraunces", serif;
  font-optical-sizing: auto;
  font-size: 21px;
  font-style: normal;
  font-variation-settings: "SOFT" 0, "WONK" 0;
  letter-spacing: 0.5px;
  text-align: justify;
  text-transform: lowercase;

  @media only screen and (max-width: 700px) {
    font-size: 18px;
  }
`;
