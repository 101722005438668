import styled from "styled-components";

import Header from "./Header";
import Home from "./Home";
import {
  BACKGROUND_COLOR,
  BORDER_RADIUS,
  FONT_COLOR,
  HIGHLIGHT_COLOR,
  SPACING,
} from "../constants";

export default function Main() {
  return (
    <Background>
      <Container>
        <SideBlock />
        <Content>
          <Header />
          <Home />
        </Content>
        <SideBlock />
      </Container>
    </Background>
  );
}

const Background = styled.div`
  background-color: ${HIGHLIGHT_COLOR};
  color: ${FONT_COLOR};
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(
    to bottom,
    rgb(145, 155, 165) 0%,
    ${HIGHLIGHT_COLOR} 70px
  );
`;

const Container = styled.div`
  justify-content: center;
  display: flex;
  margin-top: 95px;
  margin-left: 55px;
  margin-right: 55px;

  @media only screen and (max-width: 700px) {
    margin-left: ${SPACING};
    margin-right: ${SPACING};
  }
`;

const SideBlock = styled.div`
  background-color: ${BACKGROUND_COLOR};
  border-top-right-radius: ${BORDER_RADIUS};
  border-top-left-radius: ${BORDER_RADIUS};
  flex-grow: 1;
  margin-left: ${SPACING};
  margin-right: ${SPACING};
  margin-top: ${SPACING};
  opacity: 0.85;
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 1000px;
`;
