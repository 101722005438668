import styled from "styled-components";

import { BACKGROUND_COLOR, BORDER_RADIUS, SPACING } from "../constants";

export default function Header() {
  return (
    <Component>
      <Card flexGrow={10}>the recut</Card>
      <Spacer />
      <LinkCard flexGrow={6}>browse</LinkCard>
    </Component>
  );
}

const Component = styled.div`
  display: flex;
  font-family: "Fraunces", serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "SOFT" 0, "WONK" 0;
  font-size: 36px;
  height: 210px;
  justify-content: space-between;
  margin-top: ${SPACING};
  width: 100%;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

const Card = styled.div`
  align-items: center;
  background-color: ${BACKGROUND_COLOR};
  border-radius: ${BORDER_RADIUS};
  display: flex;
  flex-grow: ${({ flexGrow }) => flexGrow};
  font-size: 46px;
  justify-content: center;
  padding-bottom: 2px;
  letter-spacing: 9px;
`;

const LinkCard = styled(Card)`
  cursor: pointer;
  font-size: 26px;
  letter-spacing: 3px;

  @media only screen and (max-width: 700px) {
    margin-top: ${SPACING};
  }

  &:hover {
    opacity: 0.91;
  }
`;

const Spacer = styled.div`
  width: ${SPACING};
`;
